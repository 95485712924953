import Footer from '../components/Footer'
import Header from '../components/Header'
import HeroSection from '../components/HeroSection'
import Section from '../components/Section'
import './LandingPage.css'

function LandingPage() {

  return (
    <>
       <div className="Container">
      <Header />
      <HeroSection/>
      <Section/>
      <Footer />
    </div>
    </>
  )
}

export default LandingPage
