import "./style.scss";
import logo from "../../assets/icons/logo-kgn.png";
import point from "../../assets/icons/point-map.png";
import clock from "../../assets/icons/relogio.png";
import fone from "../../assets/icons/telefone.png";

export default function Header() {
  return (
    <>
      <div className="endereco">
        <img src={point} alt="" />
        <p>R. Lapa 35 - Chácaras Marco, Barueri - São Paulo</p>
      </div>
      <header className="header">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <nav>
            <div className="nav-links">
              <img className="icon" src={clock} alt="" />
              <div className="nav-text">
                <p className="title-text">Jornada de trabalho: </p>
                <p className="description-text"> Seg a Sex: 8:00 - 18:00</p>
              </div>

              <img className="icon-telefone" src={fone} alt="" />
              <div className="nav-text">
                <a href="#footer">
                  <p className="title-text">Contato rápido</p>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
