import React from "react";
import "./style.scss";
import { useState } from "react";
import point from "../../assets/icons/point-map.png";
import mapa from "../../assets/images/mapa.png";

export default function FormContact() {
  const [loginState, setLoginState] = useState({
    nome: "",
    email: "",
    descricao: "",
  });

  const handleInputChange = (event, key) => {
    setLoginState({ ...loginState, [key]: event.target.value });
  };

  const handleFormSubmit = () => {
    const sanitizedMessage = encodeURIComponent(loginState.descricao);
    const whatsappLink = `https://api.whatsapp.com/send?phone=5511970212956_AQUI&text=Oi%20vim%2pelo%20${encodeURIComponent(
      loginState.nome
    )}!*%20Gostaria%20de%20fazer%20um%20orçamento,%20pode%20me%20ajudar?%0AEmail:%20${encodeURIComponent(
      loginState.email
    )}%20%0A%0A*Observações:*%20${sanitizedMessage}`;
    window.open(whatsappLink);

    setLoginState({ ...loginState, nome: " ", email: " ", descricao: " " });
  };
  return (
    <div>
      <form id="form" onSubmit={handleFormSubmit}>
        <div className="content-form">
          <div className="wrap">
            <div className="section-image-form">
              <div className="text-form">
                <h1>Localização</h1>
              </div>
              <img src={mapa} alt="" />
              <div className="adress">
                <img src={point} alt=""/>
                <p>R. Lapa 35 - Chácaras Marco, Barueri - São Paulo</p>
              </div>
            </div>

            <div className="container">
              <h1>Contatos</h1>
              <div className="text-form">
                <input
                  className="name"
                  id="name"
                  type="text"
                  placeholder="Nome"
                  required
                  value={loginState.nome}
                  onChange={(event) => handleInputChange(event, "nome")}
                />
                <input
                  className="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                  required
                  value={loginState.email}
                  onChange={(event) => handleInputChange(event, "email")}
                />
                <textarea
                  name="message"
                  id="message_textarea"
                  placeholder="Mensagem"
                  value={loginState.descricao}
                  onChange={(event) => handleInputChange(event, "descricao")}
                ></textarea>
                <button>ENTRAR EM CONTATO</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
