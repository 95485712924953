import FormContact from "../FormContact";
import "./section.scss";
import React, { useState, useEffect } from "react";

import swift from "../../assets/images/swift.png";
import service from "../../assets/images/services.png";
import atacadista from "../../assets/images/max-atacadista.png";
import santander from "../../assets/images/select-santander.png";
import luminaria from "../../assets/images/luminaria.png";
import visual from "../../assets/images/visual.png";

import swiftGranada from "../../assets/images/swift-granada.png";
import persona from "../../assets/images/persona.png";
import pc from "../../assets/icons/computer.png";
import check from "../../assets/icons/check.png";
import seta from "../../assets/icons/seta-branca.png";
import setaPreta from "../../assets/icons/seta-preta.png";
export default function HeroSection() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      image: swiftGranada,
      caption: "Junte-se a nós e descubra como podemos elevar sua comunicação.",
    },

    {
      image: santander,
      caption: "Conecte-se com o mundo através de ideias impactantes.",
    },
    {
      image: luminaria,
      caption: "Transformamos sua visão em realidade.",
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };
  // const YouTubeEmbed  = ({videoId}) => {
  //   const src= `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&modestbranding=1`;
  //   return (
  //     <div className="youtube-container">
  //       <iframe
  //         src={src}
  //         allow=" autoplay; "
  //         allowFullScreen
  //         title="YouTube Video"
  //       ></iframe>
  //     </div>
  //   );
  // }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Troca a cada 5 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [slides.length]);

  return (
    <>
      <div className="container-section">
        <div className="section-content">
          <div className="section-text">
            <div className="title">
              SOBRE NÓS
              <div className="dash"></div>
            </div>
            <h2>
              CONSTRUA SUA <br /> IDEIA CONOSCO
            </h2>
            <p>
              Com mais de 9 anos de mercado, nossa empresa se consolidou como
              referência em soluções inovadoras. Ao longo dessa jornada,
              aprender que a criatividade e a qualidade são essenciais para
              atender as necessidades de nossos clientes com excelência.{" "}
            </p>
            <p>
              Cada projeto é uma nova oportunidade de explorar ideias e trazer à
              vida visões únicas, sempre com um compromisso inabalável com a
              excelência. Estamos aqui para transformar seus desafios em
              soluções visuais impactantes, criando experiências memoráveis que
              conectam marcas e pessoas.
            </p>
            <p>
              Junte-se a nós e descubra como podemos levar suas comunicação.
            </p>
            <a href="#form">
              <button>
                Mais detalhes <img src={seta} alt="" />
              </button>
            </a>
          </div>
        </div>
        <div className="section-image">
          <img src={swift} alt="" />
        </div>
      </div>

      <div className="container-section-check">
        <div className="content-check">
          <div className="wrap">
            <div className="text-check">
              <img src={pc} alt="" />
              <h2>
                {" "}
                Encontre os melhores <br /> serviços e recursos de <br />{" "}
                comunicação visual
              </h2>
            </div>
            <ul>
              <li>
                <img src={check} alt="" />
                <p>
                  Aprendemos que o comprometimento inabalável com a excelência e
                  a alta qualidade são fundamentais para atender às necessidades
                  dos nossos clientes de forma impecável.
                </p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />

                <p>
                  Cada projeto é uma oportunidade para explorar ideias e
                  materializar visões únicas, sempre guiados por princípios de
                  transparência e confiança mútua. Acreditamos que a honestidade
                  é essencial para construir relacionamentos duradouros.
                </p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />
                <p>
                  Junte-se a nós e descubra como podemos elevar sua comunicação
                  a novos patamares. Estamos prontos para enfrentar seus
                  desafios e, ao final do processo, todos estarão realmente
                  satisfeitos com nosso trabalho.
                </p>
              </li>
            </ul>
          </div>
          <div className="section-image-check">
            <div className="img-container">
              <img src={visual} alt="" />
            </div>
            <a href="#form">
              <button className="button-check">Solicite um orçamento </button>
            </a>
          </div>
        </div>
      </div>

      <div className="container-section-quality">
        <div className="content-quality">
          <div className="section-image-quality">
            <img src={service} alt="" />
          </div>
          <div className="wrap">
            <div className="text-quality">
              <div className="title-quality">
                Por que nós?
                <div className="dash"></div>
              </div>
              <h1>SERVIÇOS DE QUALIDADE</h1>
            </div>
            <ul>
              <li>
                <p>
                  Na <strong> KGN </strong>, criamos soluções personalizadas
                  para destacar sua marca, atendendo desde pequenas empresas até
                  grandes companhias.
                </p>
              </li>
              <li>
                <p>
                  <strong>Para pequenas empresas </strong>, oferecemos soluções
                  ágeis e acessíveis que ajudam a fortalecer sua identidade e
                  conquistar mais clientes. Com designs personalizados e atenção
                  aos detalhes, garantimos que seu negócio tenha a visibilidade
                  que merece, destacando-se no mercado.
                </p>
              </li>
              <li>
                <p>
                  <strong>Para grandes companhias </strong>, desenvolvemos
                  projetos robustos e estratégicos, focados em ampliar a
                  presença da sua marca de maneira impactante. Nossa equipe
                  especializada trabalha em parceria com você para criar
                  materiais visuais que transmitam a grandeza e o
                  profissionalismo da sua empresa, elevando sua imagem no
                  mercado.
                </p>
              </li>
            </ul>
            <a href="#form">
              <button>
                Contate-nos <img src={setaPreta} alt="" />
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="container-section-services">
        <div className="content-services">
          <div className="text-service">
            <div className="title-service">
              <div className="dash-service"></div>
              Serviços
              <div className="dash-service"></div>
            </div>
            <h2> Aqui na KGN você encontra: </h2>
          </div>
          <div className="wrap">
            <ul>
              <li>
                <img src={check} alt="" />
                <p>ACM</p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />
                <p>LUMINOSOS</p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />
                <p>LETRA CAIXA</p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />
                <p>ACRÍLICO</p>
              </li>
            </ul>

            <ul>
              <li>
                <img src={check} alt="" />
                <p>TOTENS</p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />
                <p>FACHADAS</p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />
                <p>COMUNICAÇÃO INTERNA</p>
              </li>
              <hr />
              <li>
                <img src={check} alt="" />
                <p>E MUITO MAIS!</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-section-results">
        <div className="content-results">
          <div className="text-results">
            <div className="title-result">
              <div className="dash-result"></div>
              Resultados
              <div className="dash-result"></div>
            </div>
            <h2> PROJETOS REALIZADOS </h2>
          </div>
          <div className="content-carroseul">
            <img src={atacadista} alt="" />

            <div className="carousel">
              <div
                className="carousel-inner"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {slides.map((slide, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${
                      index === currentIndex ? "active" : ""
                    }`}
                  >
                    <img src={slide.image} alt={`Slide ${index + 1}`} />
                    <p className="carousel-caption">{slide.caption}</p>
                  </div>
                ))}
              </div>
              <button className="carousel-control prev" onClick={handlePrev}>
                ❮
              </button>
              <button className="carousel-control next" onClick={handleNext}>
                ❯
              </button>
              <div className="carousel-indicators">
                {slides.map((_, index) => (
                  <span
                    key={index}
                    className={`indicator ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => setCurrentIndex(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-section-work">
        <div className="content-work">
          <div className="wrap">
            <div className="text-work">
              <h1>Trabalhe conosco</h1>
            </div>

            <p>
              Estamos sempre em busca dos melhores talentos. Confira noassas
              oportunidades, conheça nossos valores e se candidate!
            </p>
            <a href="#form">
              <button href="#form">VISUALIZAR NOVAS OPORTUNIDADES</button>
            </a>
          </div>
          <div className="section-image-work">
            <img src={persona} alt="" />
          </div>
        </div>
      </div>
      <FormContact />
    </>
  );
}
