import "./style.scss";

import bgImage from "../../assets/images/bg-inicio.png";
import seta from "../../assets/icons/seta-branca.png";
export default function HeroSection() {
  return (
    <div className="section" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="container">
        <section className="wrap">
          <div className="section-content">
            <div className="section-text">
              <h2>
                Contribuindo
                <br /> para o sucesso <br />
                de Marcas
              </h2>
              <p>
                Procurando a maneira perfeita de transformar sua visão em
                <br />
                realidade e impactar seu público de forma inesquecível?
              </p>
              <a href="#form">
                <button> Contate-nos <img src={seta} alt="" /></button>
              </a>
            </div>
          </div>
        </section>
        <hr />
      </div>
    </div>
  );
}
