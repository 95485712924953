import "./style.scss";
import logo from "../../assets/images/logo-inteiro.png";
import instagram from "../../assets/icons/instagram.png";
import facebook from "../../assets/icons/facebook.png";
import whatsapp from "../../assets/icons/wpp.png";
import carrefuorLogo from "../../assets/images/carrefuor-logo.png";
import drogaria from "../../assets/images/drogaria.png";
import swift from "../../assets/images/swift-logo.png";
import sams from "../../assets/images/sams.png";
import santander from "../../assets/images/santander.png";

export default function Footer() {
  const logos = [carrefuorLogo, drogaria, santander,sams,swift];

  return (
    <footer className="footer" id="footer">
      <div className="container">

        <div className="slider">
          <div className="slide-track">
            {[...logos, ...logos,  ...logos, ...logos].map((logo, index) => (
              <div key={index} className="slide">
                <img src={logo} height="100" width="250" alt="" />
              </div>
            ))}
          </div>
        </div>

        <div className="content">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="description">
            <p>
              Será um prazer atender você! com a KGN Visual, estamos
              comprometidos em oferecer serviçõs de comunicação visual de alta
              qualidade e excelência para atender as suas necessidades.
            </p>
          </div>
          <div className="social-medias">
            <div className="text">
              <p>Rede Sociais</p>
              <div className="dash"></div>
            </div>
            <div className="icons">
              <ul>
                <li>
                  <a href="https://www.instagram.com/kgnvisual?igsh=MXFpZDgwcHBmNzdzOA==">
                    <img src={instagram} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/kgnvisual?mibextid=ZbWKwL">
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <img src={whatsapp} alt="" />
                </li>
              </ul>
            </div>

            <div className="contacts">
              <p>(11) 4552-8297</p>
              <p>(11) 4552-8298</p>
            </div>
          </div>
        </div>
      </div>

      <div className="direitos">
        <p>© 2024 KGN. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}
